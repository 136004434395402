import {ReactElement, ReactNode} from 'react';

import {Point, ScenarioInputs, ScenarioQuery, User, UserGoal} from '../../query/graphql';
import {ApiHelpHelp} from '../../strapi/generated/contentTypes';
import {CostOperationId} from '../resources/costs/operationNames';
import {CropId, getCrop} from '../resources/crops';
import {CropSortKey} from '../resources/cropSortKeys';

export type WithoutNullables<T> = {[key in keyof T]: NonNullable<T[key]>};

export type WithNulls<T> = {[key in keyof T]: T[key] | null};

export type HelpText = {
  isDialogue: boolean;
  title?: string;
  content: ReactNode;
};

export type AppHelp = {
  [key in keyof ApiHelpHelp['attributes']]: HelpText;
};

export enum Fuel {
  oil = 'Oil',
  lpg = 'LPG',
  gas = 'Gas',
  electric = 'Electric',
  chip = 'Chip',
  straw = 'Straw',
  pellets = 'Pellets',
  residue_straw = 'Residue Straw',
  woodchip = 'Woodchip',
  wood_pellets = 'Woodpellets',
  coal = 'Coal',
}

export type AmountWithMC = {
  value: number;
  mc: number; // Moisture content the value applies to
};

export type Inputs = ScenarioInputs;

export type EditableInputs = ScenarioQuery['scenario']['inputs'];

export type AnnualHarvest = {
  season: number; // First year of the season, e.g. 2022 for season 2022/23
  greenYield: number[]; // Actual green yield for this year per area (tonnes/ha)
  dryMatterHarvest: number[]; // An element per area (tonnes)
  actualHarvest: number[]; // An element per area (tonnes)
  inUseHarvest: number[]; // An element per area (tonnes)
  // On combined crops, this holds data for each of the portions
  portions?: Record<
    'primary' | 'secondary',
    Pick<AnnualHarvest, 'dryMatterHarvest' | 'actualHarvest' | 'inUseHarvest'>
  >;
};

type CommonLandReportCropInfo = {
  fieldSizes: number[]; // Area sizes used in calculations -- ha
  matureYield: number; // Estimated mature yield from this Envirocrop -- t/ha/year
  harvestedMC: number; // Harvested MC used in calculations -- % (1 to 100)
  inUseMC: number; // In-use MC used in calculations -- % (1 to 100)
  cv: number; // CV used in calculations
  areaRequired: number; // Area size used in calculations -- ha
  annualHarvest: AnnualHarvest[];
};

export type SupplyPropertyLandReport = {
  goal: UserGoal.SupplyProperty;
  sortBy: CropSortKey;
  heatConsumption: number;

  results: {
    [crop in CropId]: CommonLandReportCropInfo & {
      annualRequirement: number;
      selfSufficiencyYears: string;
      bulkDensity: number; // tonnes / m3
      storageSpace: number;
    };
  };
};

export type RunPowerStationSupplyLandReport = {
  goal: UserGoal.RunPowerStation;
  sortBy: CropSortKey;
  fuelRequirement: number;

  results: {
    [crop in CropId]: CommonLandReportCropInfo & {
      landTake: number; // % within 50km radius
    };
  };
};

export type CultivateLandLandReport = {
  goal: UserGoal.CultivateLand;
  sortBy: CropSortKey;
  plantingYear: NonNullable<Inputs['plantingYear']>; // Replicates input

  results: {
    [crop in CropId]: CommonLandReportCropInfo & {
      matureAnnualProduction: number; // Green - harvested tonnes
      storageSpace: number;
    };
  };
};

export type LandReport = SupplyPropertyLandReport | RunPowerStationSupplyLandReport | CultivateLandLandReport;

export type AnnualFinance = {
  openingBalance: number;
  grantIncome: number;
  borrowingIncome: number;
  costs: number;
  borrowingPayment: number;
  income: number;
  profit: number;
  closingBalance: number;
  inflation: number;
  cumulativeInflation: number;
  // On combined crops, this holds data for each of the portions
  portions?: Record<'primary' | 'secondary', Pick<AnnualFinance, 'income'>>;
};

export type CostReport = {
  finance: {
    [crop in CropId]: {
      plantationLifetime: number; // Replicates input
      harvestedMC: number;
      inUseMC: number;
      salePrice: number;
      costs: CostCollectionSet;
      annualHarvest: AnnualHarvest[];
      annualFinance: AnnualFinance[];
      lifetime: {
        cost: {
          total: number;
          pencePerKwh: number;
          poundsPerMj: number;
          poundsPerGj: number;
          poundsPerTonne: number;
        };
        turnover: number;
        greenHarvest: number;
        dryMatterHarvest: number;
        driedHarvest: number;
        profit: {
          total: number;
          poundsPerTonne: number;
          poundsPerM3: number;
          poundsPerHaPerYr: number;
        };
      };
    };
  };
};

export type Reports = {
  land?: LandReport;
  cost?: CostReport;
};

export type Scenario = {
  inputs: Inputs;
  reports: Reports;
};

export type LocationOutput = Pick<Inputs, 'postcode' | 'coordinates'>;

export type SimpleValue = string | number | boolean | string[] | number[] | LocationOutput | Point | CostCollection;

export type OptionValue = Omit<SimpleValue, 'LocationOutput' | 'Point'>;
export type Option<T extends OptionValue> = {
  icon?: React.ReactElement;
  label: string;
  examples?: string[];
  value: T;
  tooltip?: ReactElement;
  className?: string;
};

export type Cell = {
  content: ReactNode;
  colSpan?: number;
  rowSpan?: number;
  sticky?: boolean;
  className?: string | ((highlighted: boolean) => string | undefined);
};

export type StartFlow = Pick<User, 'farmerType' | 'farmerProfile'>;

export type WithCrop<T> = {
  cropId: string;
  value: T;
}[];

export type CropMap<T> = {
  [cropId in CropId]: T;
};

export type HarvestYearDetails = {
  yearIndex: number; // Current year index
  fieldIndex: number; // Current field index (useful when staggering, otherwise 0)
  isHarvest: boolean; // Whether current year is a harvest year
  hasHarvested: boolean; // Whether the first harvest has already happened before current year
};

export type IsCostActive = (details: HarvestYearDetails) => boolean;

export type CostOperationDefinition = {
  id: CostOperationId;
  name: string;
  cost: number; // Default cost
  repetitions?: number; // Default repetitions (defaults to 1)
  enabled: boolean; // Enabled by default
  modifier?: (operation: CostOperation, details: HarvestYearDetails) => CostOperation;
};

export type CostCollectionDefinition = {
  title: string;
  costUnit: 'tonne' | 'hectare' | 'flat';
  active: IsCostActive;
  operations: CostOperationDefinition[];
};

export type CostCollectionDefinitionSet = {
  [category in keyof NonNullable<Inputs['costs']>]: CostCollectionDefinition | null;
};

export type CostOperation = Omit<CostOperationDefinition, 'cost'> & {
  defaultCost: number;
  cost: number | null;
  repetitions: number;
  isDefault: boolean;
};

export type CostCollection = Omit<CostCollectionDefinition, 'operations'> & {
  operations: CostOperation[];
};

export type CostCollectionSet = {[category in keyof NonNullable<Inputs['costs']>]: CostCollection | null};

export const SIMPLE_CALCULATOR_CROPS_NAMES = {
  [CropId.willowChip]: getCrop(CropId.willowChip).name,
  [CropId.srcPoplar]: getCrop(CropId.srcPoplar).name,
  [CropId.nitensEucalyptus1600]: 'SRF Eucalyptus',
  [CropId.miscanthusChip]: getCrop(CropId.miscanthusChip).name,
  [CropId.miscanthusBale]: getCrop(CropId.miscanthusBale).name,
};

export type SimpleCalculatorCrop = keyof typeof SIMPLE_CALCULATOR_CROPS_NAMES;
