import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, {BugsnagErrorBoundary} from '@bugsnag/plugin-react';
import {useRouter} from 'next/router';
import React, {ComponentProps, PropsWithChildren, ReactElement, useCallback, useEffect, useMemo, useState} from 'react';
import {ErrorBoundary as ReactErrorBoundary} from 'react-error-boundary';

import CustomErrorComponent from '../../pages/_error';
import URLS from '../../urls';
import store, {useAppDispatch} from '../store';
import {useEnvironment} from './EnvironmentProvider';
import {isDev} from './util';
import {subscribeToSecurityPolicyViolation} from './util/bugsnag';

const isClient = typeof window !== 'undefined';

if (isClient && isDev()) {
  console.log('Development build detected. Bugsnag (client) initialisation will be skipped.');
}

const Fallback: ComponentProps<BugsnagErrorBoundary>['FallbackComponent'] = ({error, clearError}) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const isGamePlay = router.asPath === URLS.play;

  const fixThings = useCallback(() => {
    if (isGamePlay) {
      console.log('Restarting game');
      dispatch({type: 'game/restart'});
      clearError();
    } else {
      clearError();
      void router.reload();
    }
  }, [isGamePlay, dispatch, clearError, router]);

  return (
    <CustomErrorComponent
      statusCode={(error as any).statusCode ?? 500}
      message={error.message}
      buttonLabel={isGamePlay ? 'Try to fix things' : 'Reload page'}
      onButtonClick={fixThings}
    />
  );
};

const REBFallback: ComponentProps<typeof ReactErrorBoundary>['FallbackComponent'] = ({error, resetErrorBoundary}) => {
  const safeError = error instanceof Error ? error : new Error(String(error));
  const emptyInfo = useMemo(() => ({componentStack: ''}), []);
  return <Fallback error={safeError} clearError={resetErrorBoundary} info={emptyInfo} />;
};

const ErrorBoundary = ({children}: PropsWithChildren): ReactElement | null => {
  const environment = useEnvironment();

  // 'DISABLE' value will disable Bugsnag (but the corresponding env variable must still be present)
  const bugsnagEnabled = !isDev() && environment && environment.bugsnag.apiKey.toUpperCase() !== 'DISABLE';
  const [bugsnagResolved, setBugsnagResolved] = useState(false);

  useEffect(() => {
    console.log('Subscribing to securitypolicyviolation event');
    subscribeToSecurityPolicyViolation();
  }, []);

  useEffect(() => {
    try {
      if (bugsnagEnabled) {
        console.log('Starting Bugsnag (client)');
        Bugsnag.start({
          apiKey: environment.bugsnag.apiKey,
          releaseStage: environment.bugsnag.stage ?? undefined,
          appVersion: environment.version,
          plugins: [new BugsnagPluginReact()],
          onError: event => {
            if (event.request.url?.match(`/${URLS.play}[/$]/`)) {
              event.addMetadata('game', {status: store.getState().game});
            }
          },
        });
      }
    } catch (e: any) {
      console.error(`Error starting Bugsnag:`, e.message);
    } finally {
      setBugsnagResolved(true);
    }
  }, [bugsnagEnabled, environment?.bugsnag.apiKey, environment?.bugsnag.stage, environment?.version]);

  const BugsnagErrorBoundary = useMemo(() => {
    if (!bugsnagResolved) return false;
    return bugsnagEnabled ? Bugsnag.getPlugin('react')?.createErrorBoundary(React) : undefined;
  }, [bugsnagEnabled, bugsnagResolved]);

  if (BugsnagErrorBoundary === false) {
    return null; // Don't render anything until Bugsnag presence is resolved
  }

  return BugsnagErrorBoundary ? (
    <BugsnagErrorBoundary FallbackComponent={Fallback}>{children}</BugsnagErrorBoundary>
  ) : (
    <ReactErrorBoundary FallbackComponent={REBFallback}>{children}</ReactErrorBoundary>
  );
};

export default ErrorBoundary;
